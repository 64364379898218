import { useEffect, useMemo, useRef, useState } from "react";
import { useFormAction, useNavigation } from "@remix-run/react";
import type { SerializeFrom } from "@remix-run/server-runtime";
import { parseAcceptLanguage } from "intl-parse-accept-language";
import { useSpinDelay } from "spin-delay";

import { getHints } from "./client-hints.tsx";

/**
 * @public
 * @param error
 * @returns
 */
export function getErrorMessage(error: unknown) {
  if (typeof error === "string") return error;
  if (
    error &&
    typeof error === "object" &&
    "message" in error &&
    typeof error.message === "string"
  ) {
    return error.message;
  }
  console.error("Unable to get error message for error", error);
  return "Unknown Error";
}

export function getDomainUrl(request: Request) {
  const host =
    request.headers.get("X-Forwarded-Host") ??
    request.headers.get("host") ??
    new URL(request.url).host;
  const protocol = request.headers.get("X-Forwarded-Proto") ?? "http";
  return `${protocol}://${host}`;
}

/**
 * @public
 * Merge multiple headers objects into one (uses set so headers are overridden)
 */
export function mergeHeaders(...headers: Array<ResponseInit["headers"]>) {
  const merged = new Headers();
  for (const header of headers) {
    for (const [key, value] of new Headers(header).entries()) {
      merged.set(key, value);
    }
  }
  return merged;
}

/**
 * @public
 * Combine multiple header objects into one (uses append so headers are not overridden)
 */
export function combineHeaders(...headers: Array<ResponseInit["headers"]>) {
  const combined = new Headers();
  for (const header of headers) {
    for (const [key, value] of new Headers(header).entries()) {
      combined.append(key, value);
    }
  }
  return combined;
}

/**
 * @public
 * Uses the request's accept-language header to determine the user's preferred
 * locale and the client hint cookies for the user's timeZone returns a
 * DateTimeFormat object for that locale and timezone.
 *
 * All options can be overridden by passing in an options object. By default,
 * the options are all "numeric" and the timeZone.
 */
export function getDateTimeFormat(
  request: Request,
  options?: Intl.DateTimeFormatOptions,
) {
  const locales = parseAcceptLanguage(request.headers.get("accept-language"), {
     
    validate: Intl.DateTimeFormat.supportedLocalesOf,
  });
  const locale = locales[0] ?? "en-US";

  // change your default options here
  const defaultOptions: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  options = {
    ...defaultOptions,
    ...options,
    timeZone: options?.timeZone ?? getHints(request).timeZone,
  };
  return new Intl.DateTimeFormat(locale, options);
}

/**
 * @public
 * Returns true if the current navigation is submitting the current route's
 * form. Defaults to the current route's form action and method POST.
 *
 * If GET, then this uses navigation.state === 'loading' instead of submitting.
 *
 * NOTE: the default formAction will include query params, but the
 * navigation.formAction will not, so don't use the default formAction if you
 * want to know if a form is submitting without specific query params.
 */
export function useIsSubmitting({
  formAction,
  formMethod = "POST",
}: {
  formAction?: string;
  formMethod?: "POST" | "GET" | "PUT" | "PATCH" | "DELETE";
} = {}) {
  const contextualFormAction = useFormAction();
  const navigation = useNavigation();
  return (
    navigation.state === (formMethod === "GET" ? "loading" : "submitting") &&
    navigation.formAction === (formAction ?? contextualFormAction) &&
    navigation.formMethod === formMethod
  );
}

/**
 * @public
 * This combines useSpinDelay (from https://npm.im/spin-delay) and useIsSubmitting
 * from our own utilities to give you a nice way to show a loading spinner for
 * a minimum amount of time, even if the request finishes right after the delay.
 *
 * This avoids a flash of loading state regardless of how fast or slow the
 * request is.
 */
export function useDelayedIsSubmitting({
  formAction,
  formMethod,
  delay = 400,
  minDuration = 300,
}: Parameters<typeof useIsSubmitting>[0] &
  Parameters<typeof useSpinDelay>[1] = {}) {
  const isSubmitting = useIsSubmitting({ formAction, formMethod });
  const delayedIsSubmitting = useSpinDelay(isSubmitting, {
    delay,
    minDuration,
  });
  return delayedIsSubmitting;
}

function callAll<Args extends Array<unknown>>(
  ...fns: Array<((...args: Args) => unknown) | undefined>
) {
  return (...args: Args) => fns.forEach((fn) => fn?.(...args));
}

/**
 * @public
 * Use this hook with a button and it will make it so the first click sets a
 * `doubleCheck` state to true, and the second click will actually trigger the
 * `onClick` handler. This allows you to have a button that can be like a
 * "are you sure?" experience for the user before doing destructive operations.
 */
export function useDoubleCheck() {
  const [doubleCheck, setDoubleCheck] = useState(false);

  function getButtonProps(
    props?: React.ButtonHTMLAttributes<HTMLButtonElement>,
  ) {
    const onBlur: React.ButtonHTMLAttributes<HTMLButtonElement>["onBlur"] =
      () => setDoubleCheck(false);

    const onClick: React.ButtonHTMLAttributes<HTMLButtonElement>["onClick"] =
      doubleCheck
        ? undefined
        : (e) => {
            e.preventDefault();
            setDoubleCheck(true);
          };

    return {
      ...props,
      onBlur: callAll(onBlur, props?.onBlur),
      onClick: callAll(onClick, props?.onClick),
    };
  }

  return { doubleCheck, getButtonProps };
}

/**
 * @public
 * Simple debounce implementation
 */
export function debounce<
  Callback extends (...args: Parameters<Callback>) => void,
>(fn: Callback, delay: number) {
  let timer: ReturnType<typeof setTimeout> | null = null;
  return (...args: Parameters<Callback>) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      fn(...args);
    }, delay);
  };
}

/**
 * @public
 * Debounce a callback function
 */
export function useDebounce<
  Callback extends (...args: Parameters<Callback>) => ReturnType<Callback>,
>(callback: Callback, delay: number) {
  const callbackRef = useRef(callback);
  useEffect(() => {
    callbackRef.current = callback;
  });
  return useMemo(
    () =>
      debounce(
        (...args: Parameters<Callback>) => callbackRef.current(...args),
        delay,
      ),
    [delay],
  );
}

type Indices<L extends number, T extends number[] = []> = T["length"] extends L
  ? T[number]
  : Indices<L, [T["length"], ...T]>;

type LengthAtLeast<T extends readonly any[], L extends number> = Pick<
  Required<T>,
  Indices<L>
>;

/**
 * @public
 * TypeScript type narrowing assert helper function to help with noUncheckedIndexedAccess.
 * Only usable on small array index checking (0-4). If you need to check a larger
 * array, please review your implementation and use a loop instead.
 *
 * @example
 * const names = ["John", "Doe"];
 * if (assertLengthAtLeast(names, 2)) {
 *  console.log(names[1]); // no error
 * }
 *
 */
export function assertLengthAtLeast<
  T extends readonly any[],
  I extends 0 | 1 | 2 | 3 | 4,
>(array: T, length: I): array is T & LengthAtLeast<T, I> {
  return array.length >= length;
}

export function isArrayOfMinLength<I extends 0 | 1 | 2 | 3 | 4>(
  input: unknown,
  length: I,
): input is LengthAtLeast<unknown[], I> {
  if (!Array.isArray(input)) {
    return false;
  }
  if (input.length < length) {
    return false;
  }
  return true;
}

type NoUndefinedKeys<T> = {
  [K in keyof T]: Exclude<T[K], undefined>;
};

/**
 * @public
 * @param input
 * @returns
 */
export const serializeFrom = <T>(
  input: SerializableOr<T, never>,
): SerializableOr<T, unknown> => {
  return input as SerializableOr<T, unknown>;
};

type SerializableOr<T, Fallback> =
  T extends NoUndefinedKeys<T>
    ? SerializeFrom<T> extends T
      ? T
      : Fallback
    : NoUndefinedKeys<T>;
